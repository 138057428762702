import React from 'react'
import HeroDesktop from "./hero/desktop";
import HeroPhone from "./hero/phone";


const HeroSection = () => {
    return(
        <section id='hero'>
            <div className='max-phone:hidden'>
                <HeroDesktop/>
            </div>
            <div className='hidden max-phone:block w-full h-max mb-[-242px]'>
                <HeroPhone/>
            </div>
        </section>
    );
};

export default HeroSection;