import React, {FC} from 'react';
import CATEGORIES from '../../constants/categories'

type ICategoriesList = {
    onHandleClick: (index: number)=>void,
    currentCategory: number
}
const CategoriesList: FC<ICategoriesList> = ({onHandleClick, currentCategory}) => {
    return (
        <div className='pb-12 px-2 flex flex-row gap-3 overflow-x-auto no-scrollbar text-nowrap'>
            {CATEGORIES.map((el, index) => (
                <div onClick={() => onHandleClick(index)}
                     key={index} className={currentCategory === index ?
                    'px-3 py-[0.5px] bg-main-color/[0.05] rounded-[6px] border border-main-color text-main-color cursor-pointer' :
                    'px-3 py-[0.5px] bg-background rounded-[6px] border border-grey-button cursor-pointer'}>
                    <span className='text-sm font-medium !text-nowrap' style={{
                        whiteSpace: 'nowrap',
                        wordWrap: 'break-word',
                        overflow: 'auto',
                    }}>{el}</span>
                </div>
            ))}
        </div>
    );
};

export default CategoriesList;