import React from 'react';
import AppNavbarLinks from "./links";
import ButtonApp from "../button-app";
import {handlerConnectTelegram} from "../../utils/connectTelegram";

const DesktopSection = () => {

    return (
        <section className='w-full flex flex-row justify-between max-phone:hidden'>
            <AppNavbarLinks className='flex flex-row space-x-6 text-lg font-medium items-center'/>
            <div>
                <ButtonApp text="Зв’язатися зі мною" onClick={handlerConnectTelegram} />
            </div>
        </section>

    );
};

export default DesktopSection;