import React from 'react';
import AppWrapper from "../app-wrapper";

const AppFooter = () => {
    return (
        <AppWrapper>
            <div className='w-full h-[1px] bg-grey-button'/>
            <div className='py-[31px] flex justify-center'>
                <span className='text-xs text-main-color font-medium'>Copyright© 2024 Maria. All Rights Reserved.</span>
            </div>
        </AppWrapper>
    );
};

export default AppFooter;