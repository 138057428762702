import React from 'react';
import HeroBackgroundImage from "./background-image";
import HeroArticle from "./article";
import ButtonHero from "../../button-hero";

const HeroPhone = () => {
    return (
        <>
            <HeroBackgroundImage/>
            <div className='relative flex flex-col gap-12 bottom-[258px]'>
                <HeroArticle/>
                <div className='flex content-center justify-center w-full'>
                    <ButtonHero/>
                </div>
            </div>
        </>
    );
};

export default HeroPhone;