import React, {FC} from 'react';

// @ts-ignore
import arrowLeftIcon from '../../../../assets/icons/arrow-left.svg';

type IAppPrevArrow = {
    onClick?: (e?:any)=>void
}
const AppPrevArrow: FC<IAppPrevArrow> = ({onClick}) => {

    return (
        <div
            onClick={onClick}
            style={{
                top: 'calc(50% - 70px)',
                left: 'calc(8%)'
            }}
            className={'z-10 block absolute  px-[26px] max-phone:px-[2px] py-[22px] max-phone:py-[30px] backdrop-blur-xl bg-white/30 rounded-full cursor-pointer hover:bg-white/90 max-phone:bg-white/0 max-phone:hover:bg-white/0 max-phone:backdrop-blur-none '}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" className='fill-none '>
                <path
                    d="M10.0001 19.3077L0.692383 9.99996L10.0001 0.692261L11.0636 1.75574L2.81931 9.99996L11.0636 18.2442L10.0001 19.3077Z"
                    className='fill-black max-[960px]:fill-white '/>
            </svg>
        </div>
    );
};

export default AppPrevArrow;