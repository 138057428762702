import React, {FC} from 'react';

// @ts-ignore
import arrowBackIcon from '../../../assets/icons/arrow-back.svg'

type IButtonRounded = {
    arrowBack?: boolean
    text: string
}
const ButtonRounded: FC<IButtonRounded> = ({arrowBack = false, text}) => {
    return (
        <div className='flex flex-row justify-center content-center items-center py-[10px] px-5 rounded-[60px] bg-main-color text-white text-xl max-phone:text-lg font-medium'>
            {text}
            {arrowBack && <img src={arrowBackIcon} alt='back' className='w-13 max-phone:w-8'/> }

        </div>
    );
};

export default ButtonRounded;