import React from 'react';

const HeroArticle = () => {
    return (
        <div
            className='absolute flex flex-col top-[36%] left-[80%] max-phone:w-full max-phone:static max-phone:left-0 max-phone:bottom-[0px] max-phone:justify-center max-phone:items-center max-phone:text-center max-phone:text-white'>
            {/*drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]*/}
            <h1 className='text-2xl max-phone:text-3xl font-medium text-nowrap max-phone:drop-shadow-none'>
                Створюю контент</h1>
            {/*<div className='h-[2px] w-[196px] bg-black max-phone:hidden my-[6px]'/>*/}
            <p className='text-xl font-medium'>який підкреслить твою унікальність</p>
        </div>
    );
};

export default HeroArticle;