import React, {FC} from 'react';

// @ts-ignore
import arrowRightIcon from '../../../../assets/icons/arrow-right.svg';

type IAppNextArrow = {
    onClick?: (e?:any)=>void
}
const AppNextArrow: FC<IAppNextArrow> = ({onClick}) => {

    return (
        <div
            onClick={onClick}
            style={{
                top: 'calc(50% - 70px)',
                right: 'calc(8%)'
            }}
            className='z-10 block absolute bg-white/30 backdrop-blur-xl px-[26px] max-phone:px-[2px] py-[22px] max-phone:py-[30px] rounded-full cursor-pointer max-phone:bg-white/0 max-phone:backdrop-blur-none max-phone:hover:bg-white/0 hover:bg-white/90 '
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" className='fill-none'>
                <path
                    d="M2.00004 0.692339L11.3077 10L2.00004 19.3077L0.936563 18.2443L9.18081 10L0.936563 1.75581L2.00004 0.692339Z"
                    className='fill-black max-[960px]:fill-white'/>
            </svg>
        </div>
    );
};

export default AppNextArrow;