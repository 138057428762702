import { Navigate, Route } from 'react-router-dom';
import {RouterKeys} from "./keys";
import HomePage from "../modules/home";
import PortfolioPage from "../modules/portfolio";
import AppContainer from "../shared/components/router-container";
export const publicRoutes = (
    <>
        <Route path={RouterKeys.ROOT} element={<AppContainer/>}>
            <Route path={RouterKeys.HOME} element={<HomePage/>}/>
            <Route path={RouterKeys.PORTFOLIO} element={<PortfolioPage/>}/>
        </Route>
    </>
)