import React from 'react';
import Router from "../router/router";
import {QueryClient, QueryClientProvider} from "react-query";

const AppContainer = () => {
    const queryClient = new QueryClient();

    return (
        <>
            <QueryClientProvider client={queryClient}>
                <Router/>
            </QueryClientProvider>
        </>
    );
};

export default AppContainer;