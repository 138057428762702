import React from 'react';
import {Link} from "react-router-dom";
import ButtonRounded from "../button-rounded";
import {handlerConnectTelegram} from "../../utils/connectTelegram";

const ButtonHero = () => {
    return (
        <div
            className='flex gap-[10px] w-max items-center border-[0.5px] max-phone:border-[2px] border-gray-400/20 rounded-[60px] p-[10px] bg-white/30 backdrop-blur'>
            <Link to={'/portfolio'}>
                <ButtonRounded text={'Портфоліо'} arrowBack={true}/>
            </Link>
            <div className='px-5 text-xl max-phone:text-lg font-normal cursor-pointer'>
                <button onClick={handlerConnectTelegram} >Зв'язатися</button>
            </div>
        </div>
    );
};

export default ButtonHero;