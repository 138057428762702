import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore from 'swiper';
import { Autoplay, EffectCube } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cube';

import 'swiper/css';
// @ts-ignore
import heroImage from '../../../../assets/photos/hero-phone.png'
// @ts-ignore
import heroImage1 from '../../../../assets/photos/hero-1.png'
// @ts-ignore
import heroImage2 from '../../../../assets/photos/hero-2.png'
// @ts-ignore
import heroImage3 from '../../../../assets/photos/hero-3.png'
// @ts-ignore
import heroImage4 from '../../../../assets/photos/hero-4.png'
// @ts-ignore
import heroImage5 from '../../../../assets/photos/hero-5.png'
// @ts-ignore
import heroImageDesktop from '../../../../assets/photos/hero-desktop.png'

const HeroBackgroundImage = () => {
    const PHOTOS = [
        {src: heroImage, category: 'category'},
        {src: heroImage1, category: 'category'},
        {src: heroImage2, category: 'category'},
        {src: heroImage3, category: 'category'},
        {src: heroImage4, category: 'category'},
        {src: heroImage5, category: 'category'},
    ]
SwiperCore.use([Autoplay]);
    return (
        <>
            <img src={heroImageDesktop} alt={'hero'} className='object-contain max-phone:hidden'/>
            {/*<img src={heroPhoneImage} alt={'hero'} className='object-cover w-full hidden max-phone:block'/>*/}

            <Swiper
                // effect={'cube'}
                className='!-z-30'
                autoplay={{
                    delay: 2500,
                }}
                spaceBetween={-1}
                loop={true}
                modules={[Autoplay, EffectCube]}
                speed={1800}
            >
                {PHOTOS.map((value, index)=> (
                    <SwiperSlide>
                        <img key={index} src={value.src} alt={'phot'} className='object-cover w-full hidden max-phone:block'/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
};

export default HeroBackgroundImage;