import React, {FC} from 'react';

type IAppNavbarLinks = {
    onClick?: ()=>void,
    className: string
}
const AppNavbarLinks: FC<IAppNavbarLinks> = ({onClick, className}) => {
    const LINKS = [
        {
            href:'/#portfolio',
            name: 'Портфоліо'
        },{
            href:'/#about',
            name: 'Про мене'
        },{
            href:'/#price',
            name: 'Тарифи'
        }
        // {
        //     href:'/#feedback',
        //     name: 'Відгуки'
        // }
        ,{
            href:'/#contact',
            name: 'Контакти'
        },
    ]
    return (
        <ul className={className}>
            {LINKS.map((el, index)=> (
                <li key={index} onClick={onClick}>
                    <a href={el.href}>
                        {el.name}
                    </a>
                </li>
            ))}
        </ul>
    );
};

export default AppNavbarLinks;