import React, {useState} from 'react';
import AppWrapper from "../app-wrapper";
import ButtonApp from "../button-app";
import AppSwitch from "../switch";
import PriceCard from "./price/card";

const PriceSection = () => {

    const [forBusiness, setForBusiness] = useState<boolean>(false);

    const PRICES_PERSONAL =[
        {
            title: 'STANDART',
            price: 2000,
            details:[
                {
                    header:'',
                    text:['Допомога з підбором образів;']
                },
                {
                    header:'',
                    text:['Підбір локації;']
                },
                {
                    header:'',
                    text:['Допомога з позуванням;']
                },
                {
                    header:'',
                    text:['Обробка до 10 фото;']
                },
                {
                    header:'',
                    text:['Весь матеріал передається вам одразу']
                },],
            forBusiness: false,
            time: 1
        },{
            title: 'FULL',
            price: 3900,
            details:[
                {
                    header:'',
                    text:['Допомога з підбором та комплектацією образів;']
                },
                {
                    header:'',
                    text:['Підбір локації;']
                },
                {
                    header:'',
                    text:['Допомога з позуванням;']
                },
                {
                    header:'',
                    text:['Розширений час зйомки або можливість змінювати локацію;']
                },
                {
                    header:'',
                    text:['15 оброблених фото та 1 змонтований рілс;']
                },
                {
                    header:'',
                    text:['Весь матеріал передається вам одразу.']
                },],
            forBusiness: false,
            time: 1.5
        }]
    const PRICES_BUSINESS =[
        {
            title: 'ПАКЕТ "КОНТЕНТ ПІД КЛЮЧ" (МІСЯЧНА ПІДПИСКА)',
            price: null,
            details:[
                {
                    header:'',
                    text:['Комплекс послуг для створення візуального контенту;']
                },
                {
                    header:'',
                    text:['Креативне планування зйомки з урахуванням унікальних особливостей бренду;']
                },
                {
                    header:'',
                    text:['Розробка маркетингової стратегії (оплачується окремо);']
                },
                {
                    header:'',
                    text:['Повний пакет готового матеріалу для соціальних мереж.']
                },],
            forBusiness: true,
        },
        {
            title: 'ЗЙОМКА ДЛЯ БРЕНДІВ ПІД КЛЮЧ',
            price: null,
            details:[
                {
                    header:'Щотижневий План Зйомки:',
                    text:['Планування концепцій та вибір стилізацій;']
                },
                {
                    header:'Локації та Референси:',
                    text:['Підбір цікавих локацій та підготовка референсів;'],
                },
                {
                    header:'Обробка та Монтаж Рілс:',
                    text:['Ретуш та обробка фотографій;', 'Монтаж відеороликів для соціальних мереж;'],
                },
                {
                    header:'Спільна Консультація та Корекції:',
                    text:['Регулярні онлайн-консультації для зворотнього зв\'язку та коригування плану зйомок;']
                },
            ],
            forBusiness: true,
            description: 'Цей пакет забезпечить вас високоякісним та систематичним контентом протягом місяця.'
        }]

    return (
        <section id='price'>
            <AppWrapper>
                <div className='pt-20 pb-20 max-phone:pt-12 max-phone:pb-12'>
                    <div className='flex flex-col gap-12'>
                        <div className='flex flex-col items-center gap-8'>
                            <h3 className='text-3xl font-semibold'>
                                Тарифи
                            </h3>
                            <AppSwitch firstOptText={'Особиста'} secondOptText={'Для бізнесу'} isFirstOpt={!forBusiness} setOpt={setForBusiness}/>
                        </div>
                        <div className='flex flex-row gap-8 w-full justify-center max-phone:items-center max-phone:flex-col'>
                            {!forBusiness ? PRICES_PERSONAL
                                .filter((price)=> price.forBusiness === forBusiness)
                                .map((el,index)=> (
                                    <PriceCard index={index} title={el.title} price={el.price} details={el.details} isBuisness={forBusiness} time={el.time}/>
                                )) : PRICES_BUSINESS
                                .filter((price)=> price.forBusiness === forBusiness)
                                .map((el,index)=> (
                                    <PriceCard index={index} title={el.title} details={el.details} isBuisness={forBusiness} description={el.description}/>
                                ))}

                        </div>
                        <div className='flex flex-col gap-3'>
                            <h4 className='text-xl font-medium text-gray-dark'>Умови співпраці:</h4>
                            <p className='text-lg font-normal text-gray-dark'>
                                Бронювання зйомки відбувається за попереднім записом на конкретну дату та час. Після чого здійснюється передоплата у розмірі 50% для підтвердження бронювання та початку підготовки до зйомки.
                            </p>
                        </div>
                    </div>
                </div>
            </AppWrapper>
        </section>
    );
};

export default PriceSection;