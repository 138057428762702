import React from 'react';
import AppWrapper from "../app-wrapper";
// @ts-ignore
import mailIcon from '../../../assets/icons/mail.svg'
// @ts-ignore
import instIcon from '../../../assets/icons/instagram.svg'
// @ts-ignore
import telIcon from '../../../assets/icons/telegram.svg'
import FormContact from "./contact/contact-form";
import {handlerConnectTelegram} from "../../utils/connectTelegram";

const ContactSection = () => {
    const handlerSendEmail = () => {
        window.open('mailto:hello.carrotlabs@gmail.com', '_blank');
    }

    const handlerOpenInstagram = () => {
        window.open('https://www.instagram.com/insta.podruga_', '_blank');
    }
    return (
        <section id='contact'>
            <AppWrapper>
                <div className='pt-20 max-phone:pt-12 mb-12  '>
                    <div className='flex flex-col gap-12'>
                        <h3 className='text-3xl font-semibold text-center px-10 max-phone:text-xl max-phone:px-0 max-phone:text-left'>
                            Якщо є бажання поспілкуватись та задати питання - <span className='text-main-color'>звʼяжіться зі мною:</span>
                        </h3>
                        <div className='flex flex-row justify-between max-phone:flex-col max-phone:gap-12'>
                            <div className='flex flex-col gap-16'>
                                <div className='flex flex-col gap-6'>
                                    <span className='text-sm font-bold text-gray-dark'>КОНТАКТИ</span>
                                    <div className='flex flex-row gap-4'>
                                        <img src={mailIcon}  alt="mail"/>
                                        <button className='text-lg font-semibold' onClick={handlerSendEmail}>maria.melnyk.content@gmail.com</button>
                                    </div>
                                </div>
                                <div className='flex flex-row gap-8'>
                                    <button onClick={handlerOpenInstagram}><img src={instIcon} alt="instagram"/></button>
                                    <button onClick={handlerConnectTelegram}><img src={telIcon} alt="telegram"/>
                                    </button>
                                </div>
                            </div>
                            <div className='flex flex-col gap-6 items-end max-phone:items-center'>
                                <FormContact/>
                            </div>
                        </div>
                    </div>
                </div>
            </AppWrapper>
        </section>
    );
};

export default ContactSection;