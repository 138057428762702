import React, {useEffect} from 'react';
import PortfolioTitle from "../../shared/components/portfolio/title";
import NavBar from "../../shared/components/navbar";
import AppCategories from "../../shared/components/portfolio/categories";

const PortfolioPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <NavBar colored={false}/>
            {/*<PortfolioTitle/>*/}
            <AppCategories/>
        </>
    );
};

export default PortfolioPage;