import React, {FC, useState} from 'react';
// @ts-ignore
import crossIcon from "../../../assets/icons/cross.svg";
import AppNavbarLinks from "./links";

type IPhoneSection = {
    colored?: boolean
}
const PhoneSection: FC<IPhoneSection> = ({colored}) => {
    const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
    return (<div>

        <section className='hidden float-right pt-[6px] max-phone:flex'>
            <div className={`${!isNavOpen && 'z-20'} space-y-[4px] cursor-pointer`} onClick={() => {
                setIsNavOpen(!isNavOpen)
            }}>
                <span className={colored ?
                    'bg-white block h-[1.5px] w-[17px]' :
                    'bg-black block h-[1.5px] w-[17px]'
                }></span>
                <span className={colored ?
                    'bg-white block h-[1.5px] w-[17px]' :
                    'bg-black block h-[1.5px] w-[17px]'
                }></span>
                <span className={colored ?
                    'bg-white block h-[1.5px] w-[17px]' :
                    'bg-black block h-[1.5px] w-[17px]'
                }></span>
            </div>
            <div
                className={`${isNavOpen ? 'opacity-100 z-50 ' : 'opacity-0 -z-50'} flex flex-col w-full h-screen top-0 left-0 bg-white overflow-hidden fixed transition-opacity ease-in-out delay-150 duration-300 overflow-hidden`}>
                {/*<a href='/#hero'>*/}
                    <div className='mt-10 h-max flex flex-row-reverse content-end' onClick={() => {
                        setIsNavOpen(false)
                    }}>
                        <img src={crossIcon} alt='close' className='mx-6 h-4'/>
                    </div>
                {/*</a>*/}
                {/*<ul className='m-[88px] flex flex-col flex-wrap content-center space-y-6'>*/}
                <AppNavbarLinks className='m-[88px] flex flex-col flex-wrap content-center space-y-6' onClick={()=>setIsNavOpen(false)}/>
                {/*</ul>*/}
            </div>
        </section>
    </div>
    );
};

export default PhoneSection;