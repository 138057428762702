import React, {FC, useEffect} from 'react';

import {AdvancedImage, lazyload, placeholder} from '@cloudinary/react';
import {Cloudinary} from "@cloudinary/url-gen";

// Import required actions and qualifiers.
import { auto } from "@cloudinary/url-gen/actions/resize";
import { autoGravity } from "@cloudinary/url-gen/qualifiers/gravity";

type IPhotosView = {
    photos: any,
    // photos: Array<{ src: string, categories: Array<string> }> | undefined,
    setSelectedPhotoId: (e:any, index: number)=>void
}
const PhotosView: FC<IPhotosView> = ({photos, setSelectedPhotoId}) => {
    useEffect(()=>{
        console.log('VIEW');
    },[])

    return (
        <div className='grid grid-cols-12 gap-8 place-items-center'>
            {photos?.map((el: any, index:any) => {
                const cld = new Cloudinary({
                    cloud: {
                        cloudName: 'dzzwfzziv'
                    }
                });
                const myImage = cld.image(el.public_id);
                myImage.resize( auto()
                    .width(800)
                    .height(800)
                    .gravity(autoGravity()));

                //'col-span-4 col-start-1`
                return (
                    <div
                        key={index}
                        className={index < 3 ? 'max-w-[430px] max-h-[430px] col-span-4 max-phone:col-span-12 bg-black/40' : 'bg-black/40 max-w-[360px] max-h-[360px] col-span-3 max-phone:col-span-12'}
                        onClick={(e) => {

                            setSelectedPhotoId(e, index);
                        }}>
                        {/*<img src={el.src} loading='lazy' alt={'photoExample'} width={'100%'} height={'100%'}*/}
                        {/*     className='w-full h-full object-cover'/>*/}
                        {/*<img src={el.src} loading='lazy' alt={'photoExample'} width={'100%'} height={'100%'}*/}
                        {/*     className='w-full h-full object-cover'/>*/}

                        <AdvancedImage cldImg={myImage} plugins={[placeholder({mode: 'vectorize'})]}/>
                    </div>
                    )
            })}
        </div>
    );
};

export default PhotosView;