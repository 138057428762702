import React, {FC} from 'react';
import ButtonApp from "../../button-app";
import {handlerConnectTelegram} from "../../../utils/connectTelegram";

type IPriceCard = {
    index: number,
    title: string,
    price?: number,
    details: {
        header: string,
        text: string[]
    }[],
    isBuisness: boolean,
    time?: number,
    description?: string
}

const PriceCard: FC<IPriceCard> = (
    {
        index,
        title,
        price,
        details,
        isBuisness,
        time = undefined,
        description
    }) => {
    return (
        <div key={index} className='max-w-[403px] rounded border-[1px] border-stroke-line hover:border-main-color hover:shadow-[0_5px_16px_0_rgba(13,18,130,0.5)]'>
            <div className='h-full justify-between flex flex-col items-center gap-16 py-10 px-10'>
                <div className='flex flex-col gap-12'>
                    <div className='flex flex-col justify-center items-center gap-6'>
                        <div className='font-medium text-lg text-other-body text-center -px-2'>
                            {title}
                        </div>
                        {price &&
                            <div className='font-bold text-3xl text-nowrap'>
                                {isBuisness && <span>від </span>}
                                {price}
                                <span className='text-xl font-normal'>грн</span>
                                <span className='text-lg font-normal text-gray-dark'>/{time}год</span>

                            </div>
                        }
                    </div>
                    <ul className='flex flex-col gap-6'>
                        {details.map((detail, indexI) => (
                            <div className='flex flex-col gap-1'>
                                {detail.header &&
                                    <li key={indexI}
                                        className='text-lg font-semibold'>{indexI + 1 + '. ' + detail.header}</li>
                                }
                                <ul className='flex flex-col gap-1 px-7 justify-center content-center text-lg font-medium list-disc '>
                                    {detail.text.map((text, indexJ) => (
                                        <li key={indexJ} className='text-other-body space-y-6 leading-7'>
                                            {text}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        <div>
                            <span className='block text-main-color text-base font-semibold text-center'>{description}</span>
                        </div>
                    </ul>

                </div>
                <div className='flex flex-col items-center gap-4'>
                    {!price &&
                    <span className='text-sm font-normal px-1 text-center'>
                        <span className='font-medium'>Точну вартість</span> тарифу уточнюйте особисто
                    </span>}
                    <ButtonApp text={"Зв’язатися"} onClick={handlerConnectTelegram}/>
                </div>
            </div>
        </div>
    );
};

export default PriceCard;