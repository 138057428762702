import React, {FC} from 'react';

type IAppSwitch = {
    firstOptText: string,
    secondOptText: string
    isFirstOpt: boolean,
    setOpt: (opt: boolean)=>void
}
const AppSwitch: FC<IAppSwitch> = ({firstOptText,secondOptText, isFirstOpt=true, setOpt}) => {

    const handleOnClick = (opt: boolean) => {
        setOpt(opt);
    }

    return (
        <div className='flex gap-[10px] w-max items-center border-[0.5px] max-phone:border-[2px] border-gray-400/20 rounded-[60px] p-[10px] bg-white/30 backdrop-blur'>

            <div className={isFirstOpt ?
                'flex flex-row justify-center content-center items-center py-[10px] px-5 rounded-[60px] bg-main-color' +
                ' text-white text-xl max-phone:text-lg font-medium cursor-default' :
                'px-5 text-xl max-phone:text-lg font-normal cursor-pointer'
            } onClick={()=>{handleOnClick(false)}}
            >
                {firstOptText}
            </div>
            <div className={!isFirstOpt ?
                'flex flex-row justify-center content-center items-center py-[10px] px-5 rounded-[60px] bg-main-color' +
                ' text-white text-xl max-phone:text-lg font-medium cursor-default' :
                'px-5 text-xl max-phone:text-lg font-normal cursor-pointer'
            } onClick={()=>{handleOnClick(true)}}>
                {secondOptText}
            </div>
        </div>
    );
};

export default AppSwitch;