import React, {FC} from 'react';
import {Link} from "react-router-dom";

import AppWrapper from "../app-wrapper";
import DesktopSection from "./desktop";
import PhoneSection from "./phone";

type INavBar = {
    colored?: boolean
}
const NavBar: FC<INavBar> = ({colored = true}) => {

    return (
        <AppWrapper className={colored ? 'max-phone:absolute' : ''}>
            <header className='flex flex-row flex-nowrap items-center gap-8 pt-8 pb-2 mb-[52px]'>
                <Link to='/' className={'z-50 '}>
                    {/*Maria Melnyk*/}
                    <svg width="161" height="29" xmlns="http://www.w3.org/2000/svg">
                        <text className={colored ? 'font-Montserrat font-normal text-xl fill-main-color max-phone:fill-white' :
                            'font-Montserrat font-normal text-xl fill-main-color max-phone:fill-black'}
                              y="24" x="0">Maria Melnyk</text>
                    </svg>
                </Link>
                <nav className='w-full pt-1'>
                    {/*DESKTOP*/}
                    <DesktopSection/>
                    {/*PHONE*/}
                    <PhoneSection colored={colored}/>
                </nav>
            </header>
        </AppWrapper>
    );
};


export default NavBar;