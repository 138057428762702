import React from 'react';
import HeroBackgroundImage from "./background-image";
import HeroArticle from "./article";
import ButtonHero from "../../button-hero";
import AppWrapper from "../../app-wrapper";

const HeroDesktop = () => {
    return (
        <AppWrapper>
            {/*w-10/12*/}
            <div className='relative w-7/12 -z-10 h-max'>
                <HeroBackgroundImage/>
                <HeroArticle/>
            </div>
            <div className='relative flex content-center justify-center w-full bottom-[41px]'>
                <ButtonHero/>
            </div>
        </AppWrapper>
    );
};

export default HeroDesktop;