import React from 'react';
import {Field, Form} from "react-final-form";
import ButtonApp from "../../button-app";
import {useMutation} from "react-query";
import {emailService} from "../../../services/email.service";


type IFormValues = {
    email: string,
    message: string
}
const FormContact = () => {

    const onSubmit = (values: IFormValues)=>{
        mutate(values)
    }

    const {mutate, isLoading, isError, isSuccess} = useMutation('mail',
        async (data:IFormValues)=>await emailService.sendMail(data)
    )

    return (
        <Form
            validate={(values)=>{
                const errors = {};
                if(!values.email){
                    // @ts-ignore
                    errors.email = "Пошта обов'язкова"
                }
                if(!String(values.email).toLowerCase().match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)){
                    // @ts-ignore
                    errors.email = 'Пошта невірна'
                }
                if(!values.message) {
                    // @ts-ignore
                    errors.message = 'Повідомлення пусте'
                }
                return errors;
            }}
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting , pristine}) => (
                <form onSubmit={handleSubmit} className='w-full'>
                    <div className='flex flex-col gap-3 w-full'>
                        <Field name='email'>
                            {({ input, meta }) => (
                                <div className='flex flex-col gap-1'>
                                    <input {...input} placeholder='Введіть свій email'
                                           className={'w-[500px] px-5 py-4 border-[1px] rounded border-grey-button text-sm focus:outline-0 max-phone:w-full ' +
                                               ' hover:shadow-[0px_4px_16px_0_rgba(157,0,0,0.16)] active:border-black ' + (meta.touched && meta.error && ' border-main-color')}/>
                                    {meta.touched && meta.error && <span className='text-sm text-main-color'>{meta.error}</span> }
                                </div>
                            )}
                        </Field>
                        <Field name='message'>
                            {({ input, meta }) => (
                                <div  className='flex flex-col gap-1'>
                                    <textarea {...input} draggable={false} itemScope={false}
                                              placeholder='Введіть своє повідомлення'
                                              className={'w-[500px] px-5 py-4 text-sm border-[1px] rounded border-grey-button resize-none max-phone:w-full ' +
                                                  ' focus:outline-0 hover:shadow-[0_4px_16px_0_rgba(157,0,0,0.16)] active:border-black ' + (meta.touched && meta.error && ' border-main-color')}/>
                                    {meta.touched && meta.error && <span className='text-sm text-main-color'>{meta.error}</span> }
                                </div>

                            )}

                        </Field>
                        <div className='flex justify-between w-full'>

                            {isLoading && <span className='text-sm'>Відправляємо...</span>}
                            {isSuccess && <span className='text-sm text-green-700'>Відправили!</span>}
                            {isError && <span className='text-sm text-main-color'>Виникла якась помилка</span>}
                            <ButtonApp disabled={submitting || pristine} text={'Надіслати'}/>

                        </div>
                    </div>
                </form>
            )}
        />
    );
};

export default FormContact;