import axios from 'axios';
import {EmailEndpoints} from '../constants/endpoints-keys'

class EmailService {
    private readonly baseUrl: string;
    constructor() {
        this.baseUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:3005';
    }

    public async sendMail(data: {email:string, message: string}){
        return await axios.post(
            `${this.baseUrl}/${EmailEndpoints.SEND_MAIL}`,{
                "email": data.email,
                "message": data.message
            }
        );
    }
}

export const emailService = new EmailService();
