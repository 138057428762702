import React, { useState, useEffect } from 'react';

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        isVisible ? (
            <button
                onClick={scrollToTop}
                className="fixed bottom-5 right-5 bg-main-color text-white font-bold py-3 px-5 rounded-full z-50"
                title="Вернуться наверх"
            >
                ↑
            </button>
        ) : <></>
    );
};

export default ScrollToTopButton;
