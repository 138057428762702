import React from "react";
import AppContainer from "./modules/app";
import ScrollToTopButton from "./shared/components/scroll-to-top-button";

function App() {
    return <>
        <AppContainer/>
        <ScrollToTopButton />
          </>;
}

export default App;
