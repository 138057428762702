import React, {useEffect,useState} from 'react';
import HeroSection from "../../shared/components/home/hero";
import PortfolioSection from "../../shared/components/home/portfolio";
import AboutSection from "../../shared/components/home/about";
import PriceSection from "../../shared/components/home/price";
import FeedbackSection from "../../shared/components/home/feedback";
import ContactSection from "../../shared/components/home/contact";
import AppFooter from "../../shared/components/footer";
import NavBar from "../../shared/components/navbar";

const HomePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <div>
            <NavBar colored={true}/>
            <HeroSection/>
            <AboutSection/>
            <PortfolioSection/>
            <PriceSection/>
            {/*<FeedbackSection/>*/}
            <ContactSection/>
            <AppFooter/>
        </div>
    );
};

export default HomePage;