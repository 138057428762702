import React, {FC, ReactNode} from 'react';

type IAppWrapper = {
    children?: ReactNode,
    className?: string
}
const AppWrapper: FC<IAppWrapper> = ({children, className}) => {
    return (
        // 52px
        <div className={'w-full h-max px-28 max-w-screen-xl max-phone:px-6 '+className}>
            {children}
        </div>
    );
};

export default AppWrapper;