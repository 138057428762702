import * as React from 'react';
import {  Routes } from 'react-router-dom';
import {publicRoutes} from "./routes";

const Router: React.FunctionComponent = () => {

    return (
        <Routes>
            {publicRoutes}
        </Routes>
    );
};
export default Router;