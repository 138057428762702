import React from 'react';
import AppWrapper from "../app-wrapper";

// @ts-ignore
import aboutImg from '../../../assets/photos/about.png'

const AboutSection = () => {
    return (
        <section id='about'>
            <AppWrapper>
                <div className='mt-20 pb-20 max-phone:pt-12 max-phone:pb-12'>
                    <div className='flex flex-row max-phone:flex-col justify-between max-phone:items-center max-phone:gap-12'>
                        <div className='flex flex-col gap-8 justify-center'>
                            <h1 className='text-4xl max-phone:text-3xl font-semibold'>Чому <span
                                className='text-main-color'>Я</span>?</h1>
                            <div className={'text-xl text-gray-dark'}>
                                <p className='text-wrap max-w-[690px] max-phone:text-lg'>
                                    Маю унікальне бачення та здатність транслювати прекрасне через камеру.</p>
                                <p className='mt-[20px] text-wrap max-w-[690px] max-phone:text-lg'>
                                    Під час зйомки ми робимо акцент на емоціях, дозволяючи вам глибше відчути момент та
                                    розкрити свою справжню природу, надаючи аутентичний вигляд вашій особистості через
                                    об'єктив камери.
                                    Також я створюю атмосферу, яка дозволяє вам вільно виражати себе та передавати свій
                                    неповторний вайб. </p>
                                <p className='mt-[20px] text-wrap max-w-[690px] font-medium text-main-color max-phone:text-lg'>
                                    Але обережно, мої зйомки викликають залежність ;)</p>
                            </div>
                        </div>
                        <div>
                            <img src={aboutImg} alt={'about'}/>
                        </div>
                    </div>
                </div>
            </AppWrapper>
        </section>
    );
};

export default AboutSection;