import React, {FC} from 'react';

type IButtonApp = {
    text: string,
    disabled?: boolean
    onClick?:() => void
}
const ButtonApp: FC<IButtonApp> = ({text, disabled, onClick}) => {
    return (
        <button disabled={disabled} onClick={onClick} className={'text-lg text-white px-6 py-3 rounded duration-500' +
            ' max-phone:text-base ' + (disabled ? 'bg-grey-button cursor-default' :  'bg-main-color hover:shadow-[0_4px_16px_0_rgba(157,0,0,0.4)] cursor-pointer')}>
            {text}
        </button>
    );
};

export default ButtonApp;