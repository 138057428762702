import React, {useRef, useCallback, useState, useEffect} from 'react';
import AppWrapper from "../app-wrapper";
import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation,Keyboard} from "swiper/modules";
// import 'swiper/css/navigation';
import 'swiper/css';
import AppNextArrow from "./portfolio/next-arrow";
import AppPrevArrow from "./portfolio/prev-arrow";

// @ts-ignore
import slidePhoto0 from '../../../assets/photos/slide-1.png'
// @ts-ignore
import slidePhoto1 from '../../../assets/photos/slide-2.png'
// @ts-ignore
import slidePhoto2 from '../../../assets/photos/slide-3.png'
// @ts-ignore
import slidePhoto3 from '../../../assets/photos/slide-4.png'
// @ts-ignore
import slidePhoto4 from '../../../assets/photos/slide-5.png'
// @ts-ignore
import slidePhoto5 from '../../../assets/photos/slide-6.png'
import ButtonApp from "../button-app";
import {Link} from "react-router-dom";

const PortfolioSection = () => {

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    function handleWindowSizeChange() {
        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const PHOTOS = [
        {src: slidePhoto0, category: 'Портретна зйомка'},
        {src: slidePhoto1, category: 'Зйомка для салону масажу'},
        {src: slidePhoto2, category: 'Зйомка брендів'},
        {src: slidePhoto3, category: 'Візуал під ключ'},
        {src: slidePhoto4, category: 'Зйомка для блогу'},
        {src: slidePhoto5, category: 'Предметна зйомка'},
    ]

    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        // @ts-ignore
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        // @ts-ignore
        sliderRef.current.swiper.slideNext()
    }, []);

    return (
        <section id='portfolio' >
            <div className='mb-[88px] mt-[80px] max-phone:mb-12 max-phone:pt-12'>
                <AppWrapper>
                    <div className='flex justify-between content-center items-center mb-[50px]'>
                        <h3 className='text-3xl font-semibold max-phone:text-xl'>Портфоліо</h3>
                        <Link to={'/portfolio'}><ButtonApp text={'Переглянути усе'}/></Link>
                    </div>

                </AppWrapper>
                <Swiper
                    ref={sliderRef}
                    slidesPerView={'auto'}
                    centeredSlides={true}
                    spaceBetween={windowWidth > 700 ? 32 : 24}
                    loopAddBlankSlides={true}
                    loop={true}
                    keyboard={{
                        enabled: true,
                    }}
                    navigation={true}
                    modules={[Navigation,Keyboard]}
                >
                    {PHOTOS.map((value, index) => (
                        <SwiperSlide className='!w-max'>
                            {({isActive}) => (
                                <div key={index} className={isActive ? '!w-max' : '!w-max opacity-50'}>
                                    <img src={value.src} alt={'slidePhoto'} className='h-[450px] max-[700px]:w-[360px] max-[700px]:h-auto mb-6'/>
                                    <span className='text-3xl max-phone:text-lg font-bold'>{value.category}</span>
                                </div>
                            )}
                        </SwiperSlide>
                    ))}
                    <AppPrevArrow onClick={handlePrev}/>
                    <AppNextArrow onClick={handleNext}/>
                </Swiper>
            </div>
        </section>
    );
};

export default PortfolioSection;