import axios from 'axios';
import { PhotoEndpoints } from '../constants/endpoints-keys'

class PhotoService {
    private readonly baseUrl: string;
    constructor() {
        this.baseUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:3005';
    }

    public async getAllPhotos(){
        return await axios.get(
            `${this.baseUrl}/${PhotoEndpoints.GET_ALL_PHOTOS}`,
        );
    }

    public async getPhotosByCategory(category: string) {

        return await axios.get(
            `${this.baseUrl}/${PhotoEndpoints.GET_PHOTOS_BY_CATEGORY}=${category}`
        );
    }
}

export const photoService = new PhotoService();
